import React, { useState, useEffect, useCallback, forwardRef } from "react";
import {
  FormControl,
  InputGroup,
  Button,
  Dropdown,
  Form,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faTimesCircle,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

// Default filter options based on types (can be overridden via props)
const filterOptions = {
  text: ["Contains", "Equals", "Starts with", "Ends with"],
  number: ["Is equal to", "Is greater than", "Is less than"],
  date: ["Is equal to", "Is before", "Is after"],
  scopeOptions: ["SCOPE1", "SCOPE2", "SCOPE3"],
  statusOptions: ["INCLUDED", "EXCLUDED"],
  dataSourceOptions: ["Xero", "MYOB", "CSV", "Bill"],
};

const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
  <div>
    <Form.Control
      // size="lg"
      ref={ref}
      onClick={onClick}
      value={value}
      className="ps-4"
      onChange={(e) => {
        console.log({ e });
      }}
      style={{ width: "80px", marginRight: "5px" }}
    />
    {/* <FontAwesomeIcon
          icon={faCalendarAlt}
          className="text-primary position-absolute top-50 translate-middle-y ms-2"
        /> */}
  </div>
));

const FilterBox = ({ column, filters, setFilters, startDate, endDate }) => {
  const { type, accessor } = column;

  const [filterType, setFilterType] = useState(
    filters[accessor]?.filterType || filterOptions[type]?.[0] || "Contains"
  );

  // Initialize searchValue differently for dropdown filters (arrays for scope and status)
  const [searchValue, setSearchValue] = useState(
    type === "scopeOptions" ||
      type === "statusOptions" ||
      type === "dataSourceOptions"
      ? filters[accessor]?.value || [] // Use an array for multiple selections
      : filters[accessor]?.value || "" // Use a string for other filter types
  );

  const [showDropdown, setShowDropdown] = useState(false);

  // Function to apply the filter when filter type or value is selected
  const applyFilter = useCallback(
    (updatedValue, updatedFilterType = filterType) => {
      const newFilters = {
        ...filters,
        [accessor]: {
          filterType: updatedFilterType,
          value: updatedValue,
          type,
        },
      };
      setFilters(newFilters); // Update the filters with the new value and type
    },
    [filters, accessor, filterType, setFilters, type]
  );

  // Handle dropdown selection (for scope/status filters)
  const handleDropdownSelection = (option) => {
    let updatedValue = Array.isArray(searchValue) ? [...searchValue] : [];
    if (updatedValue.includes(option)) {
      updatedValue = updatedValue.filter((item) => item !== option); // Deselect if already selected
    } else {
      updatedValue.push(option); // Select the option
    }
    setSearchValue(updatedValue); // Update the local state
    applyFilter(updatedValue); // Apply the filter
  };

  // For scope and status columns, we will show only a dropdown
  const isDropdownOnly =
    type === "scopeOptions" ||
    type === "statusOptions" ||
    type === "dataSourceOptions";

  // Handle text input changes (for text/date filters)
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value); // Update local state
    applyFilter(value); // Apply filter immediately
  };

  // Handle filter type change
  const handleFilterTypeChange = (newFilterType) => {
    setFilterType(newFilterType); // Update the selected filter type
    applyFilter(searchValue, newFilterType); // Apply the filter with the updated filter type
  };

  // Function to clear the filter
  const clearFilter = useCallback(() => {
    const newFilters = { ...filters, [accessor]: "" };
    setFilters(newFilters);
    setSearchValue(
      type === "scopeOptions" ||
        type === "statusOptions" ||
        type === "dataSourceOptions"
        ? []
        : ""
    ); // Reset searchValue based on type
  }, [filters, accessor, setFilters]);

  return (
    <InputGroup
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center", // Center vertically
        position: "relative",
      }}
    >
      {/* If it's a dropdown-only filter (scope or status), don't show the search input */}
      {!isDropdownOnly &&
        (type === "date" ? (
          <DatePicker
            selected={searchValue}
            onChange={(date) => {
              setSearchValue(date); // Update local state
              applyFilter(date); // Apply the filter immediately
            }}
            dateFormat="dd/MM/yyyy"
            placeholderText="Select Date"
            minDate={startDate}
            maxDate={endDate}
            customInput={<GreetingsDateInput />}
            style={{ marginRight: "5px" }}
          />
        ) : (
          <FormControl
            value={searchValue}
            onChange={handleInputChange}
            placeholder={`Search...`}
            style={{
              marginRight: "5px", // Add some space between input and filter button
            }}
          />
        ))}

      {/* Dropdown to select filter type */}
      <Button
        variant="outline-secondary"
        size="sm"
        onClick={() => setShowDropdown(!showDropdown)}
        style={{
          alignSelf: "center", // Ensure the button aligns vertically center
        }}
      >
        <FontAwesomeIcon icon={faFilter} />
      </Button>

      {/* Show filter options dropdown */}
      {showDropdown && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            right: 0,
            left: "auto",
            zIndex: 1000,
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: "4px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Dropdown.Menu show style={{ position: "static" }}>
            {filterOptions[type]?.map((option) => (
              <Dropdown.Item
                key={option}
                onClick={
                  type === "scopeOptions" ||
                  type === "statusOptions" ||
                  type === "dataSourceOptions"
                    ? () => handleDropdownSelection(option) // Multi-select for scope/status
                    : () => handleFilterTypeChange(option) // Filter type change for other filters
                }
              >
                {option}
                {type === "scopeOptions" ||
                type === "statusOptions" ||
                type === "dataSourceOptions"
                  ? searchValue.includes(option) && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ float: "right" }}
                      />
                    )
                  : filterType === option && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        style={{ float: "right" }}
                      />
                    )}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </div>
      )}

      <Button
        variant="outline-secondary"
        size="sm"
        onClick={clearFilter}
        disabled={
          Array.isArray(searchValue) ? !searchValue.length : !searchValue
        }
        style={{
          marginLeft: "5px", // Add space between the clear button and the filter button
        }}
      >
        <FontAwesomeIcon icon={faTimesCircle} />
      </Button>
    </InputGroup>
  );
};

export default FilterBox;

import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

const FileViewModal = ({
  showBillModal,
  setShowBillModal,
  activeUser,
  billId,
  billUrl,
}) => {
  return (
    <Modal
      show={showBillModal}
      onHide={() => setShowBillModal(false)}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Invoice {activeUser.role === "SUPER_ADMIN" && `- ${billId}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe src={billUrl} width="100%" height="500px" />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowBillModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileViewModal;

FileViewModal.propTypes = {
  showBillModal: PropTypes.bool,
  setShowBillModal: PropTypes.func,
  activeUser: PropTypes.object,
  billId: PropTypes.string,
  billUrl: PropTypes.string,
};

import React from "react";
import { Modal, Button } from "react-bootstrap";

const WarningModal = ({
  showWarningModal,
  handleWarningClose,
  handleDiscardChanges,
  handleSaveChanges,
}) => {
  return (
    <Modal show={showWarningModal} onHide={handleWarningClose}>
      <Modal.Header closeButton>
        <Modal.Title>Unsaved Changes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You have unsaved changes. Do you want to save before switching to View
        Mode?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleDiscardChanges}>
          Discard Changes
        </Button>
        <Button variant="primary" onClick={handleSaveChanges}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WarningModal;

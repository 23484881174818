import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import app from "../firebase";
import netnadaIndexDb from "../indexDbConfig";
import { formatDateToYYYYMMDD } from "./utils";
import { getActiveUserData, getSubCollectionRef } from "./store";

const fireStoreDB = getFirestore(app);
const activeUser = JSON.parse(getActiveUserData());
const UNIFIED_DATA_STORE = "unifiedData";

export const fetchUnifiedDataFromFirebaseByBusinessId = async (
  businessId,
  startDate,
  endDate
) => {
  try {
    let data = [];
    const startDateString = `${formatDateToYYYYMMDD(
      new Date(startDate)
    )}T00:00:00.000Z`;
    const endDateString = `${formatDateToYYYYMMDD(
      new Date(endDate)
    )}T00:00:00.000Z`;
    let qry;
    if (!activeUser.isFrozen) {
      qry = query(
        collection(fireStoreDB, "UnifiedData", businessId, "DataLines"),
        where("date", ">=", startDateString),
        where("date", "<=", endDateString),
        where("inclusionStatus", "==", "INCLUDED")
      );
    } else {
      const subCollectionRef = getSubCollectionRef();

      qry = query(
        collection(
          fireStoreDB,
          "FrozenUnifiedData",
          businessId,
          subCollectionRef
        ),
        where("date", ">=", startDateString),
        where("date", "<=", endDateString),
        where("inclusionStatus", "==", "INCLUDED")
      );
    }
    console.log("query", qry);
    let querySnapshot = await getDocs(qry);
    querySnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    // await setUnifiedDateToStore(data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDashboardDataFromFirebaseByBusinessId = async (
  businessId,
  startDate,
  endDate
) => {
  // console.log(startDate)
  // console.log(endDate)
  const activeUser = JSON.parse(getActiveUserData());

  const startDateString = formatDateToYYYYMMDD(new Date(startDate));
  // console.log("startDateString", startDateString);
  const endDateString = formatDateToYYYYMMDD(new Date(endDate));
  // console.log("endDateString", endDateString);

  try {
    let data = [];
    let qry;
    if (!activeUser.isFrozen) {
      qry = query(
        collection(fireStoreDB, "GroupedUnifiedData", businessId, "Groups"),
        where("date", ">=", startDateString),
        where("date", "<=", endDateString)
      );
    } else {
      const subCollectionRef = getSubCollectionRef();
      // console.log(businessId)
      // console.log(subCollectionRef)
      // console.log(startDateString)
      // console.log(endDateString)
      qry = query(
        collection(
          fireStoreDB,
          "FrozenGroupedUnifiedData",
          businessId,
          subCollectionRef
        ),
        where("date", ">=", startDateString),
        where("date", "<=", endDateString)
      );
    }

    let querySnapshot = await getDocs(qry);
    querySnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    // console.log("GroupedUnifiedData", data);
    await setUnifiedDateToStore(data);
  } catch (error) {
    console.log(error);
  }
};

export const setUnifiedDateToStore = async (data) => {
  try {
    await netnadaIndexDb.defaultStore.put({
      key: UNIFIED_DATA_STORE,
      value: JSON.stringify(data),
    });
  } catch (error) {
    console.log(error);
  }
};

export const removeUnifiedDateFromStore = async () => {
  try {
    await netnadaIndexDb.defaultStore.delete(UNIFIED_DATA_STORE);
  } catch (error) {
    console.log(error);
  }
};

export const getUnifiedDateFromStore = async () => {
  try {
    var data = await netnadaIndexDb.defaultStore.get(UNIFIED_DATA_STORE);
    // console.log("getUnifiedDateFromStore", data);
    if (data && data.value) {
      return JSON.parse(data.value);
    }
  } catch (error) {
    console.log(error);
  }
  return [];
};

import Fuse from "fuse.js";

export const applyFilters = (data, filters, searchBy, searchValue) => {
  let filteredData = data;

  // Apply column filters based on data type and filter type
  if (Object.keys(filters).length > 0) {
    Object.keys(filters).forEach((key) => {
      const filter = filters[key];

      if (filter && filter.value) {
        const { value, type, filterType } = filter;

        filteredData = filteredData.filter((row) => {
          const rowValue = row[key];

          // Handling based on the type and filter type
          switch (type) {
            case "text":
              return applyTextFilter(rowValue, value, filterType);

            case "number":
              return applyNumberFilter(rowValue, value, filterType);

            case "date":
              return applyDateFilter(rowValue, value, filterType);

            case "scopeOptions":
              return applyCategoricalFilter(rowValue, value);

            case "statusOptions":
              return applyCategoricalFilter(rowValue, value);

            default:
              return true; // If no type is matched, don't filter this field
          }
        });
      }
    });
  }

  // Apply global search if available
  if (searchValue) {
    const fieldMapping = {
      "Contact Name": "contactName",
      "Account Name": "accountName",
      Description: "description",
    };

    const fuse = new Fuse(filteredData, {
      keys: [fieldMapping[searchBy]],
      threshold: 0.8,
    });
    filteredData = fuse.search(searchValue).map((result) => result.item);
  }

  return filteredData;
};

// Helper Functions for Different Filter Types

const applyTextFilter = (rowValue, value, filterType) => {
  switch (filterType) {
    case "Contains":
      return rowValue.toLowerCase().includes(value.toLowerCase());
    case "Equals":
      return rowValue.toLowerCase() === value.toLowerCase();
    case "Starts with":
      return rowValue.toLowerCase().startsWith(value.toLowerCase());
    case "Ends with":
      return rowValue.toLowerCase().endsWith(value.toLowerCase());
    default:
      return true;
  }
};

const applyNumberFilter = (rowValue, value, filterType) => {
  const numValue = parseFloat(value);
  switch (filterType) {
    case "Is equal to":
      return rowValue === numValue;
    case "Is greater than":
      return rowValue > numValue;
    case "Is less than":
      return rowValue < numValue;
    default:
      return true;
  }
};

const applyDateFilter = (rowValue, value, filterType) => {
  const rowDate = new Date(rowValue); // Convert row value to Date object
  const filterDate = new Date(value); // Convert filter value to Date object

  // Reset time to midnight for comparison (ignores time zone differences)
  const rowDateOnly = new Date(
    rowDate.getFullYear(),
    rowDate.getMonth(),
    rowDate.getDate()
  );
  const filterDateOnly = new Date(
    filterDate.getFullYear(),
    filterDate.getMonth(),
    filterDate.getDate()
  );

  switch (filterType) {
    case "Is equal to":
      return rowDateOnly.getTime() === filterDateOnly.getTime();

    case "Is before":
      return rowDateOnly.getTime() < filterDateOnly.getTime();

    case "Is after":
      return rowDateOnly.getTime() > filterDateOnly.getTime();

    default:
      return true; // No filter applied if filterType is unknown
  }
};

const applyCategoricalFilter = (rowValue, values) => {
  if (!values || values.length === 0) {
    // If no values are selected, don't filter anything (return true for all rows)
    return true;
  }

  // Check if the rowValue matches any of the selected filter values
  return values.includes(rowValue);
};

import React, { useState } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../common/IconButton"; // Adjust import path as needed

const AllTransactionsTableHeader = ({
  isDataFrozen,
  setShowFrozenDataModal,
  setShowEditSelectedAccountNamesModal,
  selectedRowIds,
  handleBulkActions,
  isEditMode,
  toggleEditMode,
  unsavedChanges,
  saveChanges,
}) => {
  const [selectedAction, setSelectedAction] = useState("");
  return (
    <Row className="d-flex justify-content-between align-items-center mb-1">
      <Col xs={6} lg={4} className="d-flex align-items-center">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Bulk actions"
              value={selectedAction}
              onChange={(e) => setSelectedAction(e.target.value)}
            >
              <option>Bulk Actions</option>
              <option value="EXCLUDE">EXCLUDE</option>
              <option value="INCLUDE">INCLUDE</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={() => {
                if (isDataFrozen()) {
                  setShowFrozenDataModal(true);
                  return;
                }
                handleBulkActions(selectedAction);
              }}
              disabled={!selectedAction} // Disable if no valid option is selected
            >
              Apply
            </Button>
          </div>
        ) : (
          <>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon={faPenToSquare}
              transform="shrink-3"
            >
              <span
                className="d-none d-sm-inline-block ms-1"
                onClick={() => {
                  if (isDataFrozen()) {
                    setShowFrozenDataModal(true);
                    return;
                  }
                  setShowEditSelectedAccountNamesModal(true);
                }}
              >
                Account Mapping
              </span>
            </IconButton>
          </>
        )}
      </Col>

      <Col
        xs={6}
        lg={4}
        className="d-flex justify-content-end align-items-center"
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Form.Check
            type="switch"
            id="edit-view-mode-switch"
            label={isEditMode ? "Edit Mode" : "View Mode"}
            checked={isEditMode}
            onChange={toggleEditMode}
            size="lg"
            style={{ marginBottom: 0, paddingBottom: 0 }}
          />
        </div>
        {isEditMode && (
          <Button
            variant="success"
            size="sm"
            className="ms-2"
            style={{ height: "38px" }} // Adjust the button height to match the switch
            onClick={saveChanges}
            disabled={!unsavedChanges} // Only enable if there are unsaved changes
          >
            Save
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default AllTransactionsTableHeader;

import React, { useState, useEffect, useRef } from "react";

// EditableCell component that renders either an input or dropdown
const EditableCell = ({
  value: initialValue,
  rowIndex,
  columnId,
  updateMyData,
  isEditMode,
  options = [],
  secondaryOptions = [],
  emissionFactorId = "",
  inputType = "text",
  autoFocusField = false,
  handlePrimaryCategoryChange,
  handleSecondaryCategoryChange,
  toSetSecondaryCategoriesByPrimaryCategory,
}) => {
  const [value, setValue] = useState(
    columnId === "secondaryCategory"
      ? emissionFactorId || ""
      : initialValue || ""
  );

  const inputRef = useRef(null);

  // Focus on the field if autoFocusField is true
  useEffect(() => {
    if (isEditMode && autoFocusField && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode, autoFocusField]);

  // Handle changes in the input
  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);

    if (columnId === "primaryCategory") {
      // Update secondary options when the primary category changes
      const updatedSecondaryOptions =
        toSetSecondaryCategoriesByPrimaryCategory(newValue);

      // Automatically update the first secondary category and related fields
      handlePrimaryCategoryChange(
        newValue,
        rowIndex,
        updatedSecondaryOptions,
        updateMyData
      );
    } else if (columnId === "secondaryCategory") {
      handleSecondaryCategoryChange(e, rowIndex, secondaryOptions); // Handle specific secondary category selection
    } else {
      updateMyData(rowIndex, columnId, newValue); // For other fields, update data
    }
  };

  // Handle blur (when the input loses focus)
  const handleBlur = () => {
    if (value !== initialValue) {
      updateMyData(rowIndex, columnId, value);
    }
  };

  return isEditMode ? (
    inputType === "dropdown" ? (
      <DropdownCell
        value={value}
        options={options}
        secondaryOptions={secondaryOptions}
        columnId={columnId}
        handleChange={handleChange}
      />
    ) : (
      <InputCell
        value={value}
        inputRef={inputRef}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
    )
  ) : (
    <div>{value}</div>
  );
};

// Input component for text input fields
const InputCell = ({ value, inputRef, handleChange, handleBlur }) => {
  return (
    <input
      value={value}
      ref={inputRef}
      onChange={handleChange}
      onBlur={handleBlur}
      style={{
        width: "100%",
        padding: "5px",
        textAlign: "center",
        boxSizing: "border-box",
      }}
    />
  );
};

// Dropdown component for select fields
const DropdownCell = ({
  value,
  options,
  secondaryOptions,
  columnId,
  handleChange,
}) => {
  const relevantOptions =
    columnId === "secondaryCategory" ? secondaryOptions : options;
  return (
    <select
      value={value}
      onChange={handleChange}
      style={{ width: "100%", height: "30px", textAlignLast: "center" }}
    >
      {columnId === "primaryCategory" && value === "" && (
        <option value="">Please select primary category</option>
      )}
      {relevantOptions.length > 0 ? (
        columnId !== "secondaryCategory" ? (
          relevantOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))
        ) : (
          relevantOptions.map((group, idx) => (
            <optgroup key={group.groupName + idx} label={group.groupName}>
              {group.categories.map((option) => (
                <option key={option._id} value={option._id}>
                  {option.activity_name}
                </option>
              ))}
            </optgroup>
          ))
        )
      ) : (
        <option>No options available</option>
      )}
    </select>
  );
};

export default EditableCell;

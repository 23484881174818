import React from "react";
import { Modal, Form } from "react-bootstrap";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import AccountsListForm from "../../authentication/AccountsListForm";
import Flex from "../../common/Flex";

const EditSelectedAccountNamesModal = ({
  showEditSelectedAccountNamesModal,
  handleEditSelectedAccountNamesClose,
  accountsList,
  setAccountsList,
}) => {
  return (
    <div>
      {" "}
      <Modal
        show={showEditSelectedAccountNamesModal}
        onHide={() => {
          handleEditSelectedAccountNamesClose(false);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Account Mapping
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormTitle
              icon={faCheckDouble}
              title={
                "Map accounts between your accounting software and NetNada"
              }
            />
            <AccountsListForm
              accountsList={accountsList}
              setAccountsList={setAccountsList}
              isEdit={true}
              handleEditSelectedAccountNamesClose={
                handleEditSelectedAccountNamesClose
              }
            />
          </Modal.Body>
        </Form>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default EditSelectedAccountNamesModal;

const FormTitle = ({ icon, title }) => {
  return (
    <Flex className="mb-4">
      {/* <span className="fa-stack me-2 ms-n1">
          <FontAwesomeIcon icon="circle" className="fa-stack-2x text-300" />
          <FontAwesomeIcon
            icon={icon}
            className="fa-inverse fa-stack-1x text-primary"
          />
        </span> */}
      <div className="flex-1">
        <h5 className="mb-0 text-primary position-relative">
          <span className="bg-200 dark__bg-1100 pe-3">{title}</span>
          <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span>
        </h5>

        <p className="mb-0">
          This should take you no more than a few minutes. You will be able to
          edit this accounts later with the support of our team.
          <br />
          <br />
          <b>What to include:</b>
          <li>Expenses (exceptions below)</li>
          <li>Cost of goods sold</li>
          <br />
          <b>What to exclude:</b>
          <li>Salary/wages</li>
          <li>Superannuation, or</li>
          <li>Revenue</li>
          <li>Depreciation</li>
          <br />
          You can click on the table columns to sort account types or use the
          search bar.
        </p>
      </div>
    </Flex>
  );
};

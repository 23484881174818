/* eslint-disable react/prop-types */
import React from "react";
import { Form } from "react-bootstrap";
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useFilters, // Import the useFilters hook
} from "react-table";

// Checkbox for row selection
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Form.Check
        type="checkbox"
        className="form-check fs-0 mb-0 d-flex align-items-center"
      >
        <Form.Check.Input type="checkbox" ref={resolvedRef} {...rest} />
      </Form.Check>
    );
  }
);

// Default column filter component
const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value || undefined)}
      placeholder="Search"
      style={{ width: "100%" }}
    />
  );
};

// Main wrapper component
const AdvanceTableWrapper = ({
  children,
  columns,
  data,
  sortable,
  selection,
  selectionColumnWidth,
  pagination,
  perPage = 10,
}) => {
  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    pageCount,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      disableSortBy: !sortable,
      initialState: { pageSize: pagination ? perPage : data.length },
      autoResetPage: false,
      defaultColumn: {
        Filter: DefaultColumnFilter, // Define the default filter
      },
    },
    useFilters, // Add filtering hook
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (selection) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            headerProps: {
              style: {
                maxWidth: selectionColumnWidth,
              },
            },
            cellProps: {
              style: {
                maxWidth: selectionColumnWidth,
              },
            },
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  const recursiveMap = (children) => {
    return React.Children.map(children, (child) => {
      // First, check if `child` is a valid React element (it has `props`)
      if (React.isValidElement(child) && child.props) {
        // If the child has children, apply the function recursively
        if (child.props.children) {
          return React.cloneElement(child, {
            children: recursiveMap(child.props.children),
          });
        } else {
          // If `table` is in the child's props, pass the necessary table-related props
          if (child.props.table) {
            return React.cloneElement(child, {
              ...child.props,
              getTableProps,
              headers: headerGroups[0].headers, // Pass the headers from the headerGroups
              page,
              prepareRow,
              canPreviousPage,
              canNextPage,
              nextPage,
              previousPage,
              gotoPage,
              pageCount,
              pageIndex,
              selectedRowIds,
              pageSize,
              setPageSize,
              globalFilter,
              setGlobalFilter,
            });
          } else {
            // If the child has no `table` prop, return it as is
            return child;
          }
        }
      }
      // If it's not a valid React element, just return it as is (e.g., text nodes)
      return child;
    });
  };

  return <>{recursiveMap(children)}</>;
};

export default AdvanceTableWrapper;
